import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getShow, addShow, updateShow, deleteShow, searchShow } from "../../redux/actions/shows";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle, Edit } from '@mui/icons-material';

import ModalShow from '../../components/modals/show';

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null,
            searchInput:"",
            shows:[],
            page:1,
            lastId:0,
            iditem:null,
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            availableMore:true,
            isModalOpen:false,
            isLoading:false,
            modalMode:"add",
            show:{
                title:null,
                episode:null,
                slug:null,
                mixcloudurl:null,
                soundcloudurl:null,
                description:null,
                scheduled_at:null,
                scheduled_end:null,
                idimage:null,
                idaudio:null,
                idprogram:null,
                is_live:false,
                is_stream:false,
                artists:[],
                genres:[],
                tags:[],
            }
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.searchHandling = this.searchHandling.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);        
        this.handleMultipleChange = this.handleMultipleChange.bind(this);

        this.loadMoreShow = this.loadMoreShow.bind(this);
    }

    searchHandling(e){
        e.preventDefault();
        if(this.state.searchInput !== ""){
            var ctx = this;
            this.props.searchShow({searchText:this.state.searchInput}).then((res)=>{
                if(res.data !== null){
                    ctx.setState({shows:res.data, availableMore:false});
                }
            }).catch((err)=>{
                alert("Something Error");
            })
        }
    }

    handleMultipleChange(datax, idx){
        this.setState({
            show: {
                ...this.state.show,
                [idx]: datax
            }
        });
    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deleteShow({slug:this.state.show.slug}).then((res)=>{
                if(res.code === 200){
                    var joined = ctx.state.shows;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, shows: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateShow({id:this.state.iditem, show:this.state.show}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.shows;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, shows: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();

        if(this.state.show.idprogram !== null){
            this.setState({isLoading:true});
            this.props.addShow({show:this.state.show}).then((res)=>{
                if(res.code === 200){
                    var joined = [res.data[0], ...this.state.shows];
                    this.setState({ isLoading:false, shows: joined }, function(){
                        this.closeModal();
                    })
                }else{
                    this.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }else{
            alert("Program cannot be empty");
        }    
    }

    handleChange(event, newValue){        
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        let tmpVal = event.currentTarget.value;
        if(event.currentTarget.name === "slug"){
            tmpVal = event.currentTarget.value.replace(" ", "-");
        }

        this.setState({
            show: {
                ...this.state.show,
                [event.currentTarget.name]: tmpVal
            }
        });
    }

    handleSwitchChange(event, value){
        this.setState({
            show: {
                ...this.state.show,
                [event.target.name]: value
            }
        });
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            iditem:null,
            show:{
                title:null,
                episode:null,
                slug:null,
                mixcloudurl:null,
                soundcloudurl:null,
                description:null,
                scheduled_at:null,
                scheduled_end:null,
                idimage:null,
                idaudio:null,
                idprogram:null,
                is_live:false,
                is_stream:false,
                artists:[],
                genres:[],
                tags:[],       
            }
        });
    }

    openModal(event){        
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.shows.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, show:obj}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        var ctx = this;
        this.props.getShow({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){
                ctx.setState({
                    shows:ctx.state.shows.concat(res.data), 
                    page:ctx.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });
                
                if(res.data.length !== ctx.state.perpage){
                    ctx.setState({availableMore:false});
                }
            }else{
                ctx.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        var ctx = this;
        this.props.getShow({slug:"all", page:1, perpage: this.state.perpage}).then((res) =>{            
            if(res.data !== null){
                ctx.setState({shows:res.data, page:this.state.page + 1});    
                if(res.length < ctx.state.perpage){
                    ctx.setState({availableMore:false})
                }
            }else{
                ctx.setState({shows:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {
        function retrieveHost(data){
            if(data.length !== 0){
                return data.map((artist, i)=>(         
                     <a key={artist.id} href={artist.slug}>{(i !== 0)?", ":""}{artist.name}</a>
                ))
            }else{
                return <span>No Artist</span>
            }            
        }

        function retrieveGenre(data, idrow){
            if(data.length > 0){
                return data.map((genre, i)=>(
                    <span key={idrow+i}>{(i !== 0)?", ":""} <a href={"genre/"+genre.id}>{genre.name}</a></span>
                ))
            }else{
                return <span key={idrow}>No Genre</span>
            }            
        }

        function retrieveImage(data){
            if(data === null || data === undefined){
                return process.env.REACT_APP_DEFAULTIMAGE;//"https://via.placeholder.com/100";
            }else{
                return data.fullurl;
            }            
        }

        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px', gap:"10px"}}>
                    <TextField size="small" onChange={(e)=>{
                        this.setState({searchInput:e.target.value})
                    }} placeholder='Seach Title Show'></TextField>
                    <Button variant="outlined" onClick={this.searchHandling}> Search Show </Button>
                    <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Show </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Cover</StyledTableCell>
                            <StyledTableCell>Episode</StyledTableCell>
                            <StyledTableCell>Program</StyledTableCell>
                            <StyledTableCell sx={{whiteSpace:"break-spaces"}}>Show Title</StyledTableCell>
                            <StyledTableCell align="center">Genre</StyledTableCell>
                            <StyledTableCell align="center">Host</StyledTableCell>
                            <StyledTableCell align="center">Live Stream</StyledTableCell>
                            <StyledTableCell align="center">Publish at</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.shows.map((row) => {
                            return(
                                <StyledTableRow key={row.id}>
                                <StyledTableCell align="center"><img width={50} height={50}
                                    src={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={row.title}
                                    loading="lazy"
                                /></StyledTableCell>
                                <StyledTableCell>{row.episode}</StyledTableCell>
                                <StyledTableCell>{row.program?.name}</StyledTableCell>
                                <StyledTableCell component="th" scope="row" sx={{whiteSpace:"break-spaces"}}>{row.title}</StyledTableCell>
                                <StyledTableCell align="center" className="breakspace">{retrieveGenre(row.genres, row.id)}</StyledTableCell>
                                <StyledTableCell align="center" className="breakspace">{retrieveHost(row.artists)}</StyledTableCell>
                                <StyledTableCell align="center">{row.is_live.toString()}</StyledTableCell>
                                <StyledTableCell align="center" className="breakspace">{row.created_at}</StyledTableCell>
                                <StyledTableCell align="right"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Edit </Button></StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ p: 1, m: 1, bgcolor: 'background.paper',}}>
                    {(this.state.availableMore !== false)?<Button onClick={this.loadMoreShow} variant="contained" sx={{
                        margin:'auto',
                        display:'block'
                    }}>Load More</Button>:''}                
                </Box>
            </Container>
            {(this.state.isModalOpen !== false)?
                <ModalShow handleMultipleChange={this.handleMultipleChange} 
                    show={this.state.show}
                    isLoading={this.state.isLoading}
                    isModalOpen={this.state.isModalOpen}
                    modalMode={this.state.modalMode}
                    closeModal={this.closeModal}
                    handleChange={this.handleChange}
                    handleDelete={this.handleDelete}
                    handleUpdate={this.handleUpdate}
                    handleSubmit={this.handleSubmit}
                    handleSwitchChange={this.handleSwitchChange}
                />:''}
        </Box>
        )
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        getShow: data => dispatch(getShow(data)),
        addShow: data => dispatch(addShow(data)),
        searchShow: data => dispatch(searchShow(data)),
        deleteShow: data => dispatch(deleteShow(data)),
        updateShow: data => dispatch(updateShow(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Show));