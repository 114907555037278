import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'slideshow_mobile/all', 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'slideshow_mobile', {
      title: payload.slideshow.title,
      caption: payload.slideshow.caption,
      idimage: payload.slideshow.idimage,
      is_active: payload.slideshow.is_active,
      link: payload.slideshow.link,
      link_label: payload.slideshow.link_label,
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    }).then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updateSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'slideshow_mobile/'+payload.id, 
      {
        title: payload.slideshow.title,
        caption: payload.slideshow.caption,
        idimage: payload.slideshow.idimage,
        is_active: payload.slideshow.is_active,
        link: payload.slideshow.link,
        link_label: payload.slideshow.link_label,
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deleteSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'slideshow_mobile/'+payload.id,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}