import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    withRouter
} from "react-router-dom";

import { getTag } from "../../../redux/actions/tags";
import { addPress } from "../../../redux/actions/presses";
import { addLibrary, getLibrary } from "../../../redux/actions/libraries";
import { getCategory } from "../../../redux/actions/categories";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';

import { Editor } from '@tinymce/tinymce-react';

class AddPress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            library:[],
            tags:[],
            categories:[],            
            tagsSelected:[],
            categoriesSelected:[],
            imageSelected:null,
            editorRef: null,
            idimage:null,
            title: "",
            caption: "",
            description: "",
            is_draft: 0,
            is_active: 1,
            imageLoaded:false,
            err:null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handlerUpload = this.handlerUpload.bind(this);

        this.editorInit = this.editorInit.bind(this);
        this.editorChange = this.editorChange.bind(this);
    }

    handlerUpload(blobInfo, success, failure, progress){
        this.props.addLibrary({name:blobInfo.filename(), caption:null, file:blobInfo.blob(), onProgress:(e)=>{
            progress(e.loaded / e.total * 100)
        }}).then((res)=>{
            console.log(res);            
            if(res.code === 200){
                success(res.data.fullurl);
            }else{
                failure('Something Error Sorry');
            }
        });
    }

    editorChange(e){
        this.setState({description:e});
    }
    editorInit(evt, editor){
        this.setState({editorRef:editor})
    }

    handleSubmit(e){
        e.preventDefault();
        var ctx = this;
        if(this.state.title !== "" && this.state.title !== "" && this.state.description !== ""){
            this.props.addPress({
                title:this.state.title,
                caption:this.state.caption,
                description:this.state.description,
                categories:this.state.categoriesSelected,
                tags:this.state.tagsSelected,
                idimage:this.state.imageSelected.id
            }).then((e)=>{
                ctx.props.history.replace("/dashboard/press");
            })
        }else{
            alert("Content cannot be empty");
        }
    }

    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    componentDidMount(){
        var ctx = this;
        
        this.props.getTag({}).then((res)=>{
            ctx.setState({tags:res.data});
        })

        this.props.getCategory({}).then((res)=>{
            ctx.setState({categories:res.data});
        })

        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            let tmpArr = [];
            res.data.forEach(item => {
                tmpArr.push({id:item.id, title: item.name, value: item.fullurl});
            });
            ctx.setState({ library:tmpArr, imageLoaded:true });
        })
    }

    render() {
        return (
            <Box sx={{padding:"10px"}}>
                <Box component="form" onSubmit={this.handleSubmit}>
                    <Typography sx={{ display:"block", marginTop:"10px", marginBottom:"15px", fontSize:"18px", fontWeight:"800" }}>Add Press</Typography>
                    <Box className="select-cover">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Box sx={{
                                    aspectRatio:"16/9",
                                    display:"flex",
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    flexWrap:"nowrap",
                                    backgroundColor:"#dedede",
                                    marginBottom:"20px",
                                    backgroundSize:"cover",
                                    backgroundPosition:"center",
                                    backgroundImage:"url("+this.state.imageSelected?.value+")"
                                }}>
                                    {this.state.imageSelected !== null?"":"Image Cover"}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            {this.state.imageLoaded !== false?
                                <Box sx={{
                                    aspectRatio:"16/9",
                                    display:"flex",
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    flexWrap:"nowrap",
                                    marginBottom:"20px"
                                }}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            this.setState({imageSelected:newValue});
                                        }}                          
                                        name="idimage"
                                        id="input-idimage"
                                        sx={{ width: "90%" }}
                                        options={this.state.library}
                                        autoHighlight
                                        getOptionLabel={(option) => option.value}
                                        renderOption={(props, option) => (
                                            <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    name="idimage"
                                                    loading="lazy"
                                                    height="50"
                                                    src={`${option.value}`}
                                                    srcSet={`${option.value}`}
                                                    alt=""
                                                />
                                                {option.title}
                                            </Box>
                                        )}
                                        renderInput={(params) => {
                                            return <>
                                                <TextField
                                                    {...params}
                                                    name="idimage"
                                                    label="Choose an image"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            </>
                                        }}
                                    />
                                </Box>:"Loading..."
                            }
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginBottom: "20px" }}>
                        <TextField
                            fullWidth
                            required
                            id="input-title"
                            name="title"
                            label="Title"
                            placeholder="Article Title"
                            size="normal"
                            type="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                        />
                    </Box>

                    <Box sx={{ marginBottom: "20px" }}>
                        <Autocomplete
                            required
                            autoHighlight
                            filterSelectedOptions
                            name="idacategory"
                            id="input-idcategory"
                            sx={{ width: '100%' }}
                            options={this.state.categories}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                this.setState({categoriesSelected:newValue})
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                    {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="false"
                                    label="Category"
                                    placeholder="Category"
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ marginBottom: "20px" }}>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.setState({tagsSelected:newValue})
                            }}
                            name="idatag"
                            id="input-idtag"
                            sx={{ width: '100%' }}
                            options={this.state.tags}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                    {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Tag"
                                    placeholder="Tag"
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ marginBottom: "20px" }}>
                        <TextField
                            helperText="Max. 50 words"
                            fullWidth
                            required
                            multiline
                            rows={3}
                            id="input-caption"
                            name="caption"
                            label="Caption"
                            placeholder="Short Description"
                            size="normal"
                            type="caption"
                            value={this.state.caption}
                            onChange={this.handleChange}
                        />
                    </Box>
                    {this.state.imageLoaded !== false?<Editor
                        className="tinymce-editor"
                        apiKey="p4omc4npb8l0opp6vr1i3ouy60bky8sha1w4scmwmpjka3hs"
                        onInit={this.editorInit}
                        onEditorChange={this.editorChange}                        
                        // initialValue="<p>This is the initial content of the editor.</p>"
                        init={{
                            image_list:this.state.library,
                            file_picker_types: 'image media',
                            images_upload_handler : this.handlerUpload,
                            // image_advtab: true,
                            selector:'textarea',
                            height: 500,
                            menubar: true,
                            plugins: 'advlist autolink lists link image charmap print preview anchor '+
                                'searchreplace visualblocks code casechange fullscreen '+
                                'insertdatetime media table paste code help wordcount image'
                            ,
                            toolbar: 'undo redo | formatselect | casechange | ' +
                                'bold italic underline strikethrough | backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent image link | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />:"Loading..."}                    
                    <Box alignItems="right" sx={{textAlign:"right", marginTop:"10px"}}>
                        <Button type="submit" variant="contained" sx={{ minWidth:"200px" }}>Submit</Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTag: data => dispatch(getTag(data)),
        getCategory: data => dispatch(getCategory(data)),        
        addLibrary: data => dispatch(addLibrary(data)),
        addPress: data => dispatch(addPress(data)),
        getLibrary: data => dispatch(getLibrary(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPress));