import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    withRouter,
} from "react-router-dom";

import { getCountries, getCountry } from "../../redux/actions/utils";
import { getLibrary } from "../../redux/actions/libraries";
import { getProgram } from "../../redux/actions/programs";
import { getArtist } from "../../redux/actions/artists";
import { getGenre } from "../../redux/actions/genres";
import { getTag } from "../../redux/actions/tags";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';

import { AddCircle, Delete } from '@mui/icons-material';

import { subDays } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ModalShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            audios:[],
            artists:[],   
            programs:[],         
            tags:[],
            genres:[],
            err:null
        };
    }

    componentDidMount(){
        this.props.getArtist({}).then((res)=>{
            this.setState({artists:res.data});
        })

        this.props.getTag({}).then((res)=>{
            this.setState({tags:res.data});
        })

        this.props.getGenre({}).then((res)=>{
            this.setState({genres:res.data});
        })

        this.props.getProgram({}).then((res)=>{
            this.setState({programs:res.data});
        })

        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            this.setState({images:res.data});
        })

        this.props.getLibrary({slug:"all", type:"audio", perpage:10000}).then((res)=>{
            this.setState({audios:res.data});
        })
    }

    render() {
        let titlePopup = "Add Show";
        let deleteButton = "";
        let currentDate = Date.parse(this.props.show.scheduled_at);
        let endDate = Date.parse(this.props.show.scheduled_end);
        let defaultImageval = null;
        let defaultAudioval = null;
        let defaultProgramval = null;
        let defaultArtistval = [];
        let defaultGenresval = [];
        let defaultTagsval = [];

        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Show"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                defaultProgramval = this.props.show.program;
                defaultArtistval = this.props.show.artists;
                defaultGenresval = this.props.show.genres;
                defaultTagsval = this.props.show.tags;

                defaultImageval = this.props.show.idimage;
                if(defaultImageval !== null){
                    defaultImageval = this.state.images.find(o => o.id === defaultImageval);
                }
                defaultAudioval = this.props.show.idaudio;
                if(defaultAudioval !== null){
                    defaultAudioval = this.state.audios.find(o => o.id === defaultAudioval);
                }
                break;
            default:
                titlePopup = "Add Show";
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", overflow:'scroll', maxHeight:'90vh', minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}
                        //noValidate
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <Box>
                        <TextField
                            autoFocus={true} 
                            sx={{ width: 'calc(100% - 10px)' }} 
                            value={this.props.show.title || ""}
                            required={true}
                            id="input-title"
                            label="Title"
                            type="text"
                            name="title"
                            onChange={this.props.handleChange}/>
                    </Box>
                    <Box>
                        <TextField
                            sx={{ width: 'calc(100% - 10px)' }} 
                            value={this.props.show.episode || ""}
                            required={true}
                            id="input-episode"
                            label="Episode"
                            type="text"
                            name="episode"
                            onChange={this.props.handleChange}/>
                    </Box>
                    <Box>
                        <TextField
                            sx={{ width: 'calc(100% - 10px)' }} 
                            value={this.props.show.slug || ""}
                            required={true}
                            id="input-slug"
                            label="Slug"
                            type="text"
                            name="slug"
                            onChange={this.props.handleChange} 
                            inputProps={{ style: { textTransform: "lowercase" } }}/>
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-description"
                            label="description"
                            name="description"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.show.description || ""}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            onChange={this.props.handleChange}
                            required={true} 
                            name="idprogram"
                            id="input-idprogram"
                            sx={{ width: 'calc(100% - 10px)' }}
                            defaultValue={defaultProgramval}
                            options={this.state.programs}
                            autoHighlight
                            getOptionLabel={(option) => option.name}                            
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id} name="idprogram">
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                name="idprogram"
                                label="Program"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password-2',
                                }}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'artists');
                            }}
                            name="idartist"
                            id="input-idartist"
                            sx={{ width: 'calc(100% - 10px)' }}
                            value={defaultArtistval || []}
                            options={this.state.artists}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                    {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Artists"
                                    placeholder="Artists"
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'genres');
                            }}
                            name="idgenre"
                            id="input-idgenre"
                            sx={{ width: 'calc(100% - 10px)' }}
                            value={defaultGenresval || []}
                            options={this.state.genres}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                    {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Genre"
                                    placeholder="Genre"
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'tags');
                            }}
                            name="idatag"
                            id="input-idtag"
                            sx={{ width: 'calc(100% - 10px)' }}
                            value={defaultTagsval || []}
                            options={this.state.tags}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                    {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Tag"
                                    placeholder="Tag"
                                />
                            )}
                        />
                    </Box>
                                        
                    <Box sx={{padding:"10px"}}>
                        <FormGroup>
                            <FormControlLabel onChange={this.props.handleSwitchChange} name="is_live" control={<Switch name="is_live" checked={this.props.show.is_live} />} label="Is Broadcast" />
                        </FormGroup>
                    </Box>
                    
                    {this.props.show.is_live !== false?
                    <><Box>
                        <DatePicker
                            selected={currentDate}
                            id="input-scheduled_at"
                            label="Schedule At"
                            type="text"
                            name="scheduled_at" 
                            minDate={subDays(new Date(), 5)}
                            onChange={(date) => {
                                this.props.handleMultipleChange(date, 'scheduled_at');
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            customInput={<TextField sx={{ width: 'calc(100% - 10px)' }} id="input-scheduled_at" label="Schedule at" type="text" name="scheduled_at" />}
                            dateFormat="d MMMM yyyy HH:mm"
                        />
                        <DatePicker
                            selected={endDate}
                            id="input-scheduled_end"
                            label="Schedule End"
                            type="text"
                            name="scheduled_end" 
                            minDate={subDays(new Date(), 5)}
                            onChange={(date) => {
                                this.props.handleMultipleChange(date, 'scheduled_end');
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            customInput={<TextField sx={{ width: 'calc(100% - 10px)' }} id="input-scheduled_end" label="Schedule End" type="text" name="scheduled_end" />}
                            dateFormat="d MMMM yyyy HH:mm"
                        />
                    </Box>
                    <Box sx={{padding:"10px"}}>
                        <FormGroup>
                            <FormControlLabel onChange={this.props.handleSwitchChange} name="is_stream" control={<Switch name="is_stream" checked={this.props.show.is_stream} />} label="Is Streaming" />
                        </FormGroup>
                    </Box></>
                    :
                    <Box>
                        <TextField
                            sx={{ width: 'calc(100% - 10px)' }}
                            value={this.props.show.mixcloudurl || ""}
                            id="input-mixcloudurl"
                            label="Mixcloud URL"
                            type="text"
                            name="mixcloudurl"
                            onChange={this.props.handleChange}
                        />
                    </Box>
                    }

                    {this.props.show.is_stream !== true?
                    <Box>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idaudio"
                            id="input-idaudio"
                            sx={{ width: 'calc(100% - 10px)' }}
                            value={defaultAudioval || null}
                            options={this.state.audios}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => {
                                console.log(option);
                                return(
                                <Box name="idaudio" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <>
                                <center>{(params.inputProps.value !== "")?<audio src={params.inputProps.value} height="50" controls={true} alt=""/>:""}</center>
                                <TextField
                                    {...params}
                                    name="idaudio"
                                    label="Choose an audio"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password-audio',
                                    }}
                                /></>
                            )}
                        />
                    </Box>
                    :<Box 
                        sx={{
                            padding:"10px",
                            backgroundColor:"#e7e7e7",
                            borderRadius:"8px",
                            width:"calc(100% - 10px)",
                            marginBottom:"10px",
                            marginLeft:"auto",
                            marginRight:"auto"
                        }}>
                        <Typography sx={{ fontWeight:"800" }}>Streaming Info : </Typography>
                        <Typography sx={{ fontSize:"12px" }}>Host : 128.199.164.113:8000</Typography>
                        <Typography sx={{ fontSize:"12px" }}>Mount : live</Typography>
                        <Typography sx={{ fontSize:"12px" }}>User : live</Typography>
                        <Typography sx={{ fontSize:"12px" }}>Password : n00rm22live</Typography>
                    </Box>}

                    <Box>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idimage"
                            id="input-idimage"
                            value={defaultImageval || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.images}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        name="idimage"
                                        loading="lazy"
                                        height="50"
                                        src={`${option.fullurl}`}
                                        srcSet={`${option.fullurl}`}
                                        alt=""
                                    />
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return <>
                                    {(params.inputProps.value !== "")?<center><img src={params.inputProps.value} height="50" alt=""/></center>:""}
                                    <TextField
                                    {...params}
                                    name="idimage"
                                    label="Choose an image"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    />
                                </>
                            }}
                        />
                    </Box>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCountries: artistData => dispatch(getCountries(artistData)),
        getCountry: artistData => dispatch(getCountry(artistData)),
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        getProgram: libraryData => dispatch(getProgram(libraryData)),
        getArtist: libraryData => dispatch(getArtist(libraryData)),
        getGenre: libraryData => dispatch(getGenre(libraryData)),
        getTag: libraryData => dispatch(getTag(libraryData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalShow));