import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getSummary = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'summary')
    .then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const getCountries = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'country/all')
    .then((response) => {
      console.log("Get Countries")
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const getCountry = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'country/'+payload.id)
    .then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}