import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getProgram, addProgram, deleteProgram, updateProgram } from "../../redux/actions/programs";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle, Edit } from '@mui/icons-material';

import ModalProgram from '../../components/modals/program';

class Program extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programs:[],
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            page:1,
            lastId:0,
            availableMore:true,
            err:null,
            isModalOpen:false,
            isLoading:false,
            modalMode:"add",
            iditem:null,
            program:{
                name:null,
                slug:null,
                description:null,
                schedule:null,
                idimage:null,
                idartist:null,
                is_active:true,
                artists:[]
            }
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleMultipleChange = this.handleMultipleChange.bind(this);

        this.loadMoreShow = this.loadMoreShow.bind(this);
    }

    handleMultipleChange(datax, idx){
        this.setState({
            program: {
                ...this.state.program,
                [idx]: datax
            }
        });
    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deleteProgram({id:this.state.iditem}).then((res)=>{
                if(res.code === 200){
                    var joined = ctx.state.programs;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, programs: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.updateProgram({id:this.state.iditem, program:this.state.program}).then((res)=>{
                if(res.code === 200){
                    var joined = ctx.state.programs;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined[obj] = res.data;
                    ctx.setState({ isLoading:false, programs: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addProgram({program:this.state.program}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.programs];
                this.setState({ isLoading:false, programs: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        })
    }

    handleChange(event, newValue){
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        let tmpVal = event.currentTarget.value;
        if(event.currentTarget.name === "slug"){
            tmpVal = event.currentTarget.value.replace(" ", "-").toLowerCase();
        }

        if(tmpVal === ""){
            tmpVal = null;
        }

        this.setState({
            program: {
                ...this.state.program,
                [event.currentTarget.name]: tmpVal//event.currentTarget.value
            }
        });
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            iditem:null,
            program:{
                name:null,
                slug:null,
                description:null,
                schedule:null,
                idimage:null,
                idartist:null,
                is_active:true,
                artists:[]          
            }
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.programs.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, program:obj}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        this.props.getProgram({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){
                this.setState({
                    programs:this.state.programs.concat(res.data), 
                    page:this.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });
                
                if(res.length < this.state.perpage){
                    this.setState({availableMore:false})
                }
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        this.props.getProgram({slug:"all", page:1, perpage: this.state.perpage}).then((res) =>{            
            if(res.data !== null){
                this.setState({programs:res.data, page:this.state.page + 1});  
                if(res.length < this.state.perpage){
                    this.setState({availableMore:false})
                }          
            }else{
                this.setState({programs:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {    
        function retrieveHost(data){
            if(data.length !== 0){
                let hosts = data.map((row, i) => (
                    <a href={"./artists"} key={row.id}>{(i !== 0)?", ":""}{row.name}</a>
                ))
                return hosts;
            }else{
                return <span>No Artist</span>
            }            
        }

        function retrieveImage(data){
            if(data !== null){
                return data.fullurl;
            }else{
                return process.env.REACT_APP_DEFAULTIMAGE;//"https://via.placeholder.com/100";
            }            
        }
        
        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                    <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Program </Button>
                </Box>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Cover</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell>Schedule</StyledTableCell>
                            <StyledTableCell align="center">Host</StyledTableCell>
                            {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.programs.map((row) => (
                            <StyledTableRow key={row.id}>
                            <StyledTableCell align="center"><img width={50} height={50}
                                src={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={row.title}
                                loading="lazy"
                            /></StyledTableCell>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.schedule}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row">
                                {retrieveHost(row.artists)}
                            </StyledTableCell>
                            <StyledTableCell align="right"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Edit </Button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ p: 1, m: 1, bgcolor: 'background.paper',}}>
                    {(this.state.availableMore !== false)?<Button onClick={this.loadMoreShow} variant="contained" sx={{
                        margin:'auto',
                        display:'block'
                    }}>Load More</Button>:''}                
                </Box>
            </Container>
            { (this.state.isModalOpen !== false)?<ModalProgram handleMultipleChange={this.handleMultipleChange} isLoading={this.state.isLoading} program={this.state.program} modalMode={this.state.modalMode} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} handleChange={this.handleChange} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit}/>:'' }
        </Box>
        )
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        getProgram: programData => dispatch(getProgram(programData)),
        addProgram: programData => dispatch(addProgram(programData)),
        deleteProgram: programData => dispatch(deleteProgram(programData)),
        updateProgram: programData => dispatch(updateProgram(programData))  
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Program));