import React, { Component } from 'react';
import { connect } from "react-redux";

import { format } from "date-fns";

import {
    withRouter,
} from "react-router-dom";

import { getSummary } from "../../redux/actions/utils";
import { getSchedule } from "../../redux/actions/shows";

import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary:{},
            schedule:[],
            err:null
        };
    }

    componentDidMount(){
        this.props.getSummary().then((e)=>{
            this.setState({summary:e.data});
        })

        this.props.getSchedule().then((e)=>{
            this.setState({schedule:e.data});
        })
    }
    
    render() {
        var scheduleContainer = "No Upcoming Show!";
        if(this.state.schedule.length !== 0){
            scheduleContainer = this.state.schedule.map((item, idx)=>{
                return(
                    <Box key={idx} sx={{display:"flex", alignItems:"center", marginTop:"5px", marginBottom:"5px"}}>
                        <Box sx={{display:"flex", alignItems:"center", width:"70px"}}>
                            <img width="70px" src={item.image?.fullurl} alt={item.title || "image cover"}/>
                        </Box>
                        <Box sx={{display:"flex", alignItems:"center", width:"calc(100% - 70px)"}}>
                            <Box sx={{padding:"5px"}}>
                                <Typography sx={{display:"block", fontSize:"20px"}}>{item.title}</Typography>
                                <Typography sx={{display:"block"}}>{format(new Date(item.scheduled_at), "dd MMMM yyyy KK:mm aa")} - {format(new Date(item.scheduled_end), "dd MMMM yyyy KK:mm aa")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        }

        return (
            <Box sx={{ display: 'flex' }}>
            <Container component="div" className='panel-section-container'>
                <CssBaseline />
                <Grid container spacing={2}>                    
                    <Grid item xs={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{fontSize:"20px", textAlign:"center"}}>All Shows</Typography>
                                <Box sx={{display:"flex", alignItems:"center", minHeight:"150px", justifyContent:"center"}}>
                                    <Typography sx={{fontSize:"5em", fontWeight:"800"}}>{this.state.summary?.shows || 0}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{fontSize:"20px", textAlign:"center"}}>Programs</Typography>
                                <Box sx={{display:"flex", alignItems:"center", minHeight:"150px", justifyContent:"center"}}>
                                    <Typography sx={{fontSize:"5em", fontWeight:"800"}}>{this.state.summary?.programs || 0}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{fontSize:"20px", textAlign:"center"}}>Artists</Typography>
                                <Box sx={{display:"flex", alignItems:"center", minHeight:"150px", justifyContent:"center"}}>
                                    <Typography sx={{fontSize:"5em", fontWeight:"800"}}>{this.state.summary?.artists || 0}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{fontSize:"20px", textAlign:"center"}}>Schedule</Typography>
                                <Box className="table-schedule" sx={{marginTop:"20px"}}>
                                    {scheduleContainer}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSummary: userData => dispatch(getSummary(userData)),
        getSchedule: userData => dispatch(getSchedule(userData))
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));