import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    Redirect,
    withRouter
} from "react-router-dom";

import { login, checkLogin} from "../redux/actions/users";

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin:false,
            from:'/dashboard',
            err:null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.props.checkLogin().then((res) => {
          if(res.code === 200){
            this.setState({isLogin:true, from:this.props.location?.state?.from?.pathname});
          }else{
            this.setState({isLogin:false});
          }
        });
    }

    handleSubmit(event){
        event.preventDefault();

        let ctx = this;
        ctx.setState({err:null});

        const data = new FormData(event.currentTarget);
        this.props.login({
            email: data.get('email'),
            password: data.get('password'),
        }).then(function (data){
            if(data.code !== 200){
                ctx.setState({err:data.message});
            }else{
                ctx.setState({isLogin:true});
            }
        }).catch(function (e) {
            ctx.setState({err:"Something error"});
        });
    }

    render() {        
        let err = "";

        if(this.state.err !== null){
            err = <Grid container className="alert-text">
                    <Grid item xs>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {this.state.err} - <strong>Check again</strong>
                    </Alert>
                    </Grid>
                </Grid>;
        }

        return (
            <Container component="main" maxWidth="xs" className="login-page">
                <CssBaseline />
                {(this.state.isLogin === true)?<Redirect to={this.state.from}></Redirect>:''}
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        />
                        {err}
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: userData => dispatch(login(userData)),
        checkLogin: userData => dispatch(checkLogin(userData))        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));