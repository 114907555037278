import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getSlideshow, addSlideshow, updateSlideshow, deleteSlideshow } from "../../redux/actions/slideshows_mobile";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle, Edit } from '@mui/icons-material';

import ModalSlideshow from '../../components/modals/slideshow';

class SlideshowMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideshows:[],
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            page:1,
            lastId:0,
            err:null,
            isModalOpen:false,
            isLoading:false,
            modalMode:"add",
            iditem:null,
            slideshow:{
                title:null,
                caption:null,
                is_active:1,
                idimage:null,
                link:null,
                link_label:null,
            }
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.loadMoreShow = this.loadMoreShow.bind(this);
    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deleteSlideshow({id:this.state.iditem}).then((res)=>{
                if(res.code === 200){
                    var joined = ctx.state.slideshows;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, slideshows: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:true});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateSlideshow({id:this.state.iditem, slideshow:this.state.slideshow}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.slideshows;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, slideshows: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:true});
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addSlideshow({slideshow:this.state.slideshow}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.slideshows];
                this.setState({ isLoading:false, slideshows: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:true});
                alert("Something Error");
            }
        })
    }

    handleChange(event, newValue){
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        let tmpVal = event.currentTarget.value;
        if(tmpVal === ""){
            tmpVal = null;
        }
        this.setState({
            slideshow: {
                ...this.state.slideshow,
                [event.currentTarget.name]: tmpVal
            }
        });
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            iditem:null,
            slideshow:{
                title:null,
                caption:null,
                is_active:1,
                idimage:null,
                link:null,
                link_label:null,
            }
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.slideshows.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, slideshow:obj}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        var ctx = this;
        this.props.getSlideshow({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            console.log(res);

            if(res.data !== null){
                ctx.setState({
                    slideshows:ctx.state.slideshows.concat(res.data), 
                    page:ctx.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        this.props.getSlideshow({slug:"all"}).then((res) =>{
            console.log(res);
            
            if(res.data !== null){
                this.setState({slideshows:res.data});     
            }else{
                this.setState({slideshows:[]})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {        
        function retrieveImage(data){
            if(data !== null){
                return data.fullurl;
            }else{
                return process.env.REACT_APP_DEFAULTIMAGE;
            }            
        }

        return (
            <Box sx={{ display: 'block' }}>
                <Container component="div" className='panel-section-container' maxWidth="xl">
                    <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                        <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Slideshow </Button>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Cover</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell align="center">Caption</StyledTableCell> 
                                <StyledTableCell align="center">Link</StyledTableCell> 
                                <StyledTableCell align="center">Status</StyledTableCell> 
                                <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.slideshows.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center"><img height={150}
                                        src={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={row.title}
                                        loading="lazy"
                                    /></StyledTableCell>
                                    <StyledTableCell>{row.title}</StyledTableCell>
                                    <StyledTableCell>{row.caption}</StyledTableCell>
                                    <StyledTableCell>{row.link}</StyledTableCell>
                                    <StyledTableCell>{row.is_active.toString()}</StyledTableCell>
                                    <StyledTableCell align="right"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Edit </Button></StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                { (this.state.isModalOpen !== false)?<ModalSlideshow isLoading={this.state.isLoading} slideshow={this.state.slideshow} modalMode={this.state.modalMode} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} handleChange={this.handleChange} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit}/>:'' }
            </Box>
        )
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        getSlideshow: data => dispatch(getSlideshow(data)),
        addSlideshow: data => dispatch(addSlideshow(data)),
        updateSlideshow: data => dispatch(updateSlideshow(data)),
        deleteSlideshow: data => dispatch(deleteSlideshow(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideshowMobile));