import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getTag, addTag, updateTag, deleteTag } from "../../redux/actions/tags";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle, Edit } from '@mui/icons-material';

import TagModal from '../../components/modals/tag';

class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags:[],
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            page:1,
            lastId:0,
            availableMore:true,
            err:null,
            isModalOpen:false,
            iditem:null,
            name:null,
            idimage:null,
            caption:null,
            is_active:true,
            isLoading:false
        };

        this.loadMoreShow = this.loadMoreShow.bind(this);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deleteTag({id:this.state.iditem}).then((res)=>{
                if(res.code === 200){
                    var joined = ctx.state.tags;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, tags: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateTag({id:this.state.iditem, idimage:this.state.idimage, name:this.state.name, is_active: this.state.is_active, caption:this.state.caption}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.tags;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, tags: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addTag({name:this.state.name, idimage:this.state.idimage, is_active: this.state.is_active, caption:this.state.caption}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.tags];
                this.setState({ isLoading:false, tags: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        })
    }

    handleChange(event, newValue){       
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        if(event.currentTarget.name !== "is_active"){
            this.setState({[event.currentTarget.name]:event.currentTarget.value});
        }else{
            let val = true;
            (event.currentTarget.value === "true")? val = false: val=true;
            this.setState({[event.currentTarget.name]:val});
        }
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            name:null,
            caption:null,
            is_active:true,
            iditem:null,
            idimage:null,
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.tags.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, is_active: obj.is_active, name:obj.name, idimage:obj.idimage, caption:obj.caption}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({name:null, caption:null, idimage:null, is_active: true, isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        this.props.getTag({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){
                this.setState({
                    tags:this.state.tags.concat(res.data), 
                    page:this.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });

                if(res.length < this.state.perpage){
                    this.setState({availableMore:false})
                }
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        this.props.getTag({slug:"all", page:1, perpage: this.state.perpage}).then((res) =>{            
            if(res.data !== null){
                this.setState({tags:res.data, page:this.state.page + 1});    
                if(res.length < this.state.perpage){
                    this.setState({availableMore:false})
                }          
            }else{
                this.setState({tags:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {
        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                    <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Tag </Button>
                </Box>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Cover</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell align="left">Description</StyledTableCell>   
                            <StyledTableCell align="center">Status</StyledTableCell>                               
                            <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.tags.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell><img alt={"image for "+row.name} width="75px" src={row.image?.fullurl || "https://norrm.s3.ap-southeast-1.amazonaws.com/library/norrm-icon.png?w=164&h=164&fit=crop&auto=format&dpr=2"}/></StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell>{row.caption}</StyledTableCell>
                                <StyledTableCell align="center">{row.is_active.toString()}</StyledTableCell>
                                <StyledTableCell align="center"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Details </Button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ p: 1, m: 1, bgcolor: 'background.paper' }}>
                    {(this.state.availableMore !== false)?<Button onClick={this.loadMoreShow} variant="contained" sx={{
                        margin:'auto',
                        display:'block'
                    }}>Load More</Button>:''}            
                </Box>
            </Container>
            {(this.state.isModalOpen === true)?<TagModal modalMode={this.state.modalMode} name={this.state.name} idimage={this.state.idimage} is_active={this.state.is_active} caption={this.state.caption} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit} handleChange={this.handleChange} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal}/>:""}
        </Box>
        )
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        getTag: tagData => dispatch(getTag(tagData)),
        addTag: tagData => dispatch(addTag(tagData)),
        updateTag: tagData => dispatch(updateTag(tagData)),
        deleteTag: tagData => dispatch(deleteTag(tagData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tag));