import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {
        return (
            <div className='main-container-panel'>
                <span className='main-container-panel-label'>Coming Soon</span>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule));