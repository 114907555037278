import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    Switch,
    Route
} from "react-router-dom";

// import { login } from "../redux/actions/users";
// import Slider from "../pages/dashboards/slider";

import Sidemenu from "../components/sidemenu";

import EditPress from "../pages/dashboards/press/editPress";
import AddPress from "../pages/dashboards/press/addPress";
import Slideshow from "../pages/dashboards/slideshow";
import SlideshowMobile from "../pages/dashboards/slideshow_mobile";
import Schedule from "../pages/dashboards/schedule";
import Playlist from "../pages/dashboards/playlist";
import Category from "../pages/dashboards/category";
import Program from "../pages/dashboards/program";
import Setting from "../pages/dashboards/setting";
import Library from "../pages/dashboards/library";
import Artist from "../pages/dashboards/artist";
import Press from "../pages/dashboards/press";
import Genre from "../pages/dashboards/genre";
import Home from "../pages/dashboards/home";
import Show from "../pages/dashboards/show";
import Tag from "../pages/dashboards/tag";

import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };

        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handleSubmit(event){
    //     event.preventDefault();
    //     let ctx = this;
    //     ctx.setState({ err:null });
    //     const data = new FormData(event.currentTarget);
    //     this.props.login({
    //         email: data.get('email'),
    //         password: data.get('password'),
    //     }).then(function (data){
    //         if(data.code !== 200){
    //             ctx.setState({err:data.message});
    //         }
    //     }).catch(function (e) {
    //         ctx.setState({err:"Something error"});
    //     });
    // }

    render() {
        const url = this.props.match.url;
        return (
        <Container component="main" className='main-section-container' maxWidth={false}>
            <CssBaseline />
            <Box className='section-left-panel'><Sidemenu /></Box>
            <Box className='section-right-panel'>
                <Switch>                        
                    <Route exact path={`${url}/`}>
                        <Home />
                    </Route>
                    <Route path={`${url}/schedule`}>
                        <Schedule />
                    </Route>
                    <Route path={`${url}/playlist`}>
                        <Playlist />
                    </Route>
                    {/* <Route path={`${url}/streamer`}>
                        <Playlist />
                    </Route> */}
                    <Route path={`${url}/shows`}>
                        <Show />
                    </Route>
                    <Route path={`${url}/programs`}>
                        <Program />
                    </Route>
                    <Route path={`${url}/artists`}>
                        <Artist />
                    </Route>
                    <Route path={`${url}/tags`}>
                        <Tag />
                    </Route>
                    <Route path={`${url}/genres`}>
                        <Genre />
                    </Route>
                    <Route path={`${url}/library`}>
                        <Library />
                    </Route>
                    <Route exact path={`${url}/press/add`}>
                        <AddPress />
                    </Route>
                    <Route path={`${url}/press/edit/:slug`}>
                        <EditPress />
                    </Route>
                    <Route exact path={`${url}/press`}>
                        <Press />
                    </Route>
                    <Route path={`${url}/category`}>
                        <Category />
                    </Route>
                    <Route path={`${url}/slideshow`}>
                        <Slideshow />
                    </Route>
                    <Route path={`${url}/slideshow_mobile`}>
                        <SlideshowMobile />
                    </Route>
                    <Route path={`${url}/settings`}>
                        <Setting />
                    </Route>                            
                </Switch>
            </Box>
        </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // login: userData => dispatch(login(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));