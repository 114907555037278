import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Link,
    withRouter
} from "react-router-dom";

import { logout } from "../redux/actions/users";

import Box from '@mui/material/Box';
//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err:{}
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.logout = this.logout.bind(this);
    }

    logout(){
        let ctx = this;
        this.props.logout().then(function(){
            ctx.props.history.push("/");
        })
    }

    toggleMenu(params){

    }

    componentDidMount(){
        //console.log('header mounted');
    }

    render() {
        let loginLogout = '';
        if(this.props.isLogin === false){
            loginLogout = <li><Link to='/login'>Login</Link></li>
        }else{
            loginLogout = <li><Link to='#' onClick={this.logout}>Logout</Link></li>
        }

        return (
            <Box className='main-header'>
                <div className='logo-wrapper'><li><Link to='/'>Norrm</Link></li></div>
                <div className='nav-link'>
                    <ul>
                        {loginLogout}
                    </ul>
                </div>
            </Box>
        )
    }
}

function mapStateToProps(state){
    return{
        isLogin: state.isLogin,
        userData: state.userData
    }
}

// const mapStateToProps = state => ({
//     ...state
// });

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        logout, //login, checkLogin
    }, dispatch)
  }

// function mapDispatchToProps(dispatch) {
//     return {
//         logout
//         //logout: userData => dispatch(logout(userData))
//     };
// }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));