import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { login, checkLogin} from "./redux/actions/users";

// import Box from '@mui/material/Box';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from "./components/header"
import Footer from "./components/footer"

import Login from "./pages/login"
import Dashboard from "./pages/index"

import {
  BrowserRouter as Router,
	Route,
	Switch,
  Redirect
} from "react-router-dom";

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isLoaded: false,
      // from:'/dashboard',
      err:{
        message:'',
        code:0,
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLogin !== this.state.isLogin) {
      this.setState({isLogin:this.props.isLogin})
    }
  }

  render() {
    const theme = createTheme();
    
    return (
      <ThemeProvider theme={theme}>
        <Box>
        <Router basename="/">
          {/* {(this.state.isLogin === true)?<Redirect to={this.state.from}></Redirect>:''} */}
          <Header />
          <Box className='body'>
            <Switch>
              <PrivateRoute loginState={this.state.isLogin} path="/dashboard">
                <Dashboard/>
              </PrivateRoute>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/">
                <Redirect to="/dashboard"></Redirect>
              </Route>
            </Switch>
          </Box>
          <Footer />
        </Router>
      </Box>
      </ThemeProvider>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (    
    <Route
      {...rest}
      render={({ location }) =>
        (rest.loginState !== false) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  ...state
});

// function mapDispatchToProps(dispatch){
//   return bindActionCreators({
//     login,
//     checkLogin,
//     formatDate
//   }, dispatch)
// }

// function mapDispatchToProps(dispatch) {
//   return {
//       login,
//       checkLogin
//   };
// }

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    login, checkLogin
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);