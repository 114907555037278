import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getLibrary } from "../../redux/actions/libraries";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {        
        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <span>Setting</span>
            </Container>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));