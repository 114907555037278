import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

import { getLibrary } from "../../redux/actions/libraries";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { AddCircle, Delete } from '@mui/icons-material';

class ModalSlideshow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            err:{}
        };

    }

    componentDidMount(){
        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            this.setState({images:res.data});
        })
    }

    render() {
        let titlePopup = "Add Slideshow";
        let deleteButton = "";
        let defaultImageval = null;

        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Slideshow"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                defaultImageval = this.props.slideshow.idimage;
                if(defaultImageval !== null){
                    defaultImageval = this.state.images.find(o => o.id === defaultImageval);
                }
                break;
            default:
                titlePopup = "Add Slideshow";
                defaultImageval = this.props.slideshow.idimage;
                if(defaultImageval !== null){
                    defaultImageval = this.state.images.find(o => o.id === defaultImageval);
                }
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-caption"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", overflow:'scroll', maxHeight:'90vh', minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.slideshow.title || ""} required={true} id="input-name" label="Title" type="text" name="title" onChange={this.props.handleChange}/>
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-caption"
                            label="caption"
                            name="caption"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.slideshow.caption || ""}
                        />
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-link"
                            label="Link"
                            name="link"
                            placeholder="https://www.instagram.com"
                            onChange={this.props.handleChange}
                            value={this.props.slideshow.link || ""}
                        />
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-link_label"
                            label="Label Link"
                            name="link_label"
                            placeholder="Learn More"
                            onChange={this.props.handleChange}
                            value={this.props.slideshow.link_label || ""}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idimage"
                            id="input-idimage"
                            value={defaultImageval || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.images}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    name="idimage"
                                    loading="lazy"
                                    height="50"
                                    src={`${option.fullurl}`}
                                    srcSet={`${option.fullurl}`}
                                    alt=""
                                />
                                {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <>
                                    <Box sx={{padding:"5px"}}>
                                        {(params.inputProps.value !== "")?<img src={params.inputProps.value} height="120" alt=""/>:""}
                                    </Box>
                                    <TextField
                                    {...params}
                                    name="idimage"
                                    label="Choose an image"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    />
                                </>
                            )}
                        />
                    </Box>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalSlideshow));