import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getSchedule = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'schedule')
    .then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const searchShow = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'show/search/'+payload.searchText+paramPage,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getShow = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'show/'+payload.slug+paramPage,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addShow = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'show', 
      {
        title:payload.show.title,
        episode:payload.show.episode,
        slug:payload.show.slug,
        mixcloudurl:payload.show.mixcloudurl,
        soundcloudurl:payload.show.soundcloudurl,
        description:payload.show.description,
        scheduled_at:payload.show.scheduled_at,
        scheduled_end:payload.show.scheduled_end,
        is_live:payload.show.is_live,
        is_stream:payload.show.is_stream,
        idimage:payload.show.idimage,
        idaudio:payload.show.idaudio,
        idprogram:payload.show.idprogram,
        artists:payload.show.artists,
        genres:payload.show.genres,
        tags:payload.show.tags
      }, {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      }).then((response) => {
        payload = response.data;
        return payload;
      }).catch((error) => {
        return error;
      })
    )
  }
}

export const updateShow = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'show/'+payload.id, 
      {
        title:payload.show.title,
        episode:payload.show.episode,
        slug:payload.show.slug,
        mixcloudurl:payload.show.mixcloudurl,
        soundcloudurl:payload.show.soundcloudurl,
        description:payload.show.description,
        scheduled_at:payload.show.scheduled_at,
        scheduled_end:payload.show.scheduled_end,
        is_live:payload.show.is_live,
        is_stream:payload.show.is_stream,
        idimage:payload.show.idimage,
        idaudio:payload.show.idaudio,
        idprogram:payload.show.idprogram,
        artists:payload.show.artists,
        genres:payload.show.genres,
        tags:payload.show.tags
      }, {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then((response) => {
        payload = response.data;
        return payload;
      }).catch((error) => {
        payload = error;
        return payload;
      })
    )
  }
}

export const deleteShow = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'show/'+payload.slug, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}