import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { getLibrary } from "../../redux/actions/libraries";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { AddCircle, Delete } from '@mui/icons-material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

class ModalGenre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            err:{}
        };

    }

    componentDidMount(){
        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            this.setState({images:res.data});
        })
    }

    render() {
        let titlePopup = "Add Genre";
        let deleteButton = "";
        let defaultImageval = null;
        
        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Genre"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>

                defaultImageval = this.props.idimage;
                if(defaultImageval !== null){
                    defaultImageval = this.state.images.find(o => o.id === defaultImageval);
                }

                break;
            default:
                titlePopup = "Add Genre";
                if(this.props.idimage !== null){
                    defaultImageval = this.state.images.find(o => o.id === this.props.idimage);
                }

                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.name || ""} required={true} id="input-name" label="Name" type="text" name="name" onChange={this.props.handleChange}/>
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-caption"
                            label="Caption"
                            name="caption"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.caption || ""}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            control={<Switch 
                            id="input-is_active"
                            name="is_active"
                            value={this.props.is_active}
                            checked={this.props.is_active} 
                            onChange={this.props.handleChange} />}
                            label="Is Active?"
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idimage"
                            id="input-idimage"
                            value={defaultImageval || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.images}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        name="idimage"
                                        loading="lazy"
                                        height="50"
                                        src={`${option.fullurl}`}
                                        srcSet={`${option.fullurl}`}
                                        alt=""
                                    />
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <>
                                    <Box sx={{padding:"5px"}}>
                                        {(params.inputProps.value !== "")?<img src={params.inputProps.value} height="120" alt=""/>:""}
                                    </Box>
                                    <TextField
                                    {...params}
                                    name="idimage"
                                    label="Choose an image"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                </>
                            )}
                        />
                    </Box>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        // updateLibrary: libraryData => dispatch(updateLibrary(libraryData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalGenre));