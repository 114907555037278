import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

// export const checkLogin = () => {
//   return (dispatch) => {
//     let token;
//     if(window.localStorage.getItem('app_tt') !== undefined){
//       token = window.localStorage.getItem('app_tt');
//     }else{
//       dispatch({ type: 'SET_LOGOUT' })
//       token = undefined;
//     }
//     return token;
//   }
// }

export const checkLogin = () => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'me', {headers: { Authorization: "Bearer "+window.localStorage.getItem('app_tt') },validateStatus: () => true})
      .then(function (response) {
        let payload = response.data;
        if(payload.code === 200){
          dispatch({ type: 'STAY_INSIDE', payload })
        }else{
          dispatch({ type: 'SET_LOGOUT', payload })
        }
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const login = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'signin', {
      email: payload.email,
      password: payload.password
    })
    .then((response) => {
      payload = response.data;
      if(response.data.code === 200){
        dispatch({ type: 'SET_LOGIN', payload })
      }
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const logout = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'signout', null, {headers: { Authorization: "Bearer "+window.localStorage.getItem('app_tt') },validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_LOGOUT', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}