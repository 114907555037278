import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getPlaylist = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'playlist/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addPlaylist = (payload) => {
  return (dispatch) => {    
    return( axios.post(BASE_URL+BASE_VERSION+'playlist', {
      name: payload.playlist.name,
      url: payload.playlist.url,
      description: payload.playlist.description,
      idimage: payload.playlist.idimage,
      is_active: payload.playlist.is_active,
      audios:payload.playlist.audio
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const startPlaylist = (payload) => {
  return (dispatch) => {    
    return( axios.post(BASE_URL+BASE_VERSION+'playlist/start/'+payload.id, null, 
    {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}
export const stopPlaylist = (payload) => {
  return (dispatch) => {    
    return( axios.post(BASE_URL+BASE_VERSION+'playlist/stop/'+payload.id, null, 
    {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    }).then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updatePlaylist = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'playlist/'+payload.id, 
      {
        name: payload.playlist.name,
        url: payload.playlist.url,
        description: payload.playlist.description,
        idimage: payload.playlist.idimage,
        is_active: payload.playlist.is_active,
        audios:payload.playlist.audio
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deletePlaylist = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'playlist/'+payload.id,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}