import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:{}
        };
        
        //this.onLogout = this.onLogout.bind(this);
    }

    // onLogout(params) {
        
    // }

    componentDidMount(){
        //console.log('header mounted');
    }

    render() {
        return (
            <div className='main-footer'>
                <span className='footer-label'>2021 Norrm</span>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));