import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';

class EditPress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {
        // const url = this.props.match.url;
        return (
            <Box>
                Edit Press
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPress));