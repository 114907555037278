import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getLibrary, addLibrary, updateLibrary, deleteLibrary } from "../../redux/actions/libraries";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import { AddCircle, Edit } from '@mui/icons-material';

import ModalLibrary from '../../components/modals/library';

class Library extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:1,
            lastId:0,
            progressValue:"",
            availableMore:true,
            libraries:[],
            isModalOpen:false,
            modalMode:"add",
            iditem:null,
            name:null,
            caption:null,
            file:null,
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            err:null,
            isLoading:false
        };
        
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.onProgress = this.onProgress.bind(this);
        this.loadMoreLibrary = this.loadMoreLibrary.bind(this);
    }

    onProgress(e){
        // console.log(e);        
        console.log(Math.floor(e.loaded * 0.000001)+" Mb");
        console.log(e.loaded / e.total * 100+"%");
        // let loaded = e.loaded / e.total * 100;
        this.setState({progressValue:Math.floor(e.loaded / e.total * 100)})
    }

    handleChange(event){
        this.setState({[event.currentTarget.name]:event.currentTarget.value});
        if(event.currentTarget.files !== null && event.currentTarget.files !== undefined){
            this.setState({file:event.currentTarget.files[0]});
        }
    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deleteLibrary({id:this.state.iditem, onProgress:this.onProgress}).then((res)=>{
                console.log(res);
                if(res.code === 200){
                    var joined = ctx.state.libraries;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, libraries: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:false});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateLibrary({id:this.state.iditem, name:this.state.name, caption:this.state.caption, onProgress:this.onProgress}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.libraries;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, libraries: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addLibrary({name:this.state.name, caption:this.state.caption, file:this.state.file, onProgress:this.onProgress}).then((res)=>{
            console.log(res);
            if(res.code === 200){
                var joined = [res.data, ...this.state.libraries];
                this.setState({ isLoading:false, libraries: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:false});
                alert("Something Error");
            }
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.libraries.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, name:obj.name, caption:obj.caption}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({name:null, caption:null, isModalOpen:true, modalMode:modalmode});            
        }
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            name:null,
            caption:null,
            file:null
        });
    }

    componentDidMount(){
        // https://www.mixcloud.com/oauth/authorize?client_id=kGyKv8DeDgdkzD9XP7&redirect_uri=https://dashboard.norrm.com/mixcloud_auth
        // https://www.mixcloud.com/oauth/access_token?client_id=kGyKv8DeDgdkzD9XP7&redirect_uri=https://dashboard.norrm.com/mixcloud_auth&client_secret=pZLXnJfL8mcwgsyxFwecfG7fgcPnsdqd&code=OAUTH_CODE

        this.props.getLibrary({slug:"all", page:1, perpage: this.state.perpage}).then((res)=>{
            if(res.data !== null){
                this.setState({libraries:res.data, page:this.state.page + 1});       
            }else{
                this.setState({libraries:[], availableMore:false})
            }
        });
    }

    loadMoreLibrary(){
        this.props.getLibrary({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){                
                this.setState({
                    libraries:this.state.libraries.concat(res.data), 
                    page:this.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });
                
                if(res.data.length !== this.state.perpage){
                    this.setState({availableMore:false});
                }
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {
        let libraryItem = <span>Nothing's found</span>;
        if(this.state.libraries.length > 0 ){
            libraryItem = this.state.libraries.map((item) => (
                <div key={item.id} className='library-grid-item'>
                    {(item.type === 'image')?<CardMedia component="img" image={item.fullurl} src={item.fullurl} sx={{ }}></CardMedia>:
                    <div className='library-grid-item-audio'><audio src={item.fullurl} controls={true}></audio><span>{retriveFile(item.url)}</span></div>}
                    <div className='library-grid-item-info'><Button variant="contained" startIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={item.id}> Edit </Button></div>
                </div>
            ));
        }

        function retriveFile(url){
            let filename = url.indexOf("/") + 1;
            return url.substr(filename);
        }

        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                    <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal} modal-mode="add"> Add Library </Button>
                </Box>
                <div className='library-grid-item-container'>
                    {libraryItem}                        
                </div>
                <Box sx={{
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    }}>
                    {(this.state.availableMore !== false)?<Button onClick={this.loadMoreLibrary} variant="contained" sx={{
                        margin:'auto',
                        display:'block'
                    }}>Load More</Button>:''}                
                </Box>
            </Container>
            { (this.state.isModalOpen !== false)?
            <ModalLibrary 
                progressValue={this.state.progressValue}
                isLoading={this.state.isLoading}
                name={this.state.name}
                caption={this.state.caption} 
                modalMode={this.state.modalMode} 
                isModalOpen={this.state.isModalOpen} 
                closeModal={this.closeModal} 
                handleChange={this.handleChange} 
                handleDelete={this.handleDelete} 
                handleUpdate={this.handleUpdate} 
                handleSubmit={this.handleSubmit}/>:'' }
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        addLibrary: libraryData => dispatch(addLibrary(libraryData)),
        updateLibrary: libraryData => dispatch(updateLibrary(libraryData)),
        deleteLibrary: libraryData => dispatch(deleteLibrary(libraryData)),        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Library));