import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    withRouter
} from "react-router-dom";

import { getLibrary, updateLibrary } from "../../redux/actions/libraries";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AddCircle, Delete } from '@mui/icons-material';

class ModalLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {
        let titlePopup = "Upload Library";
        let deleteButton = "";        
        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Library"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                break;
            default:
                titlePopup = "Upload Library";
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.name || ""} required={true} id="input-name" label="Name" type="text" name="name" onChange={this.props.handleChange}/>
                    </Box>
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-caption"
                            label="Caption"
                            name="caption"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.caption || ""}
                        />
                    </Box>{(this.props.modalMode !== "edit")?
                    <Box>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} id="input-file" type="file" name="file" onChange={this.props.handleChange}/>
                    </Box>:""}
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?
                        <Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Uploading {this.props.progressValue.toString() | ""}%</Button>:
                        <Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>
                        }
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        updateLibrary: libraryData => dispatch(updateLibrary(libraryData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalLibrary));