import React, { Component } from 'react';
import { connect } from "react-redux";

// import parse from 'html-react-parser';

import {
    NavLink,
    withRouter,
} from "react-router-dom";

// import { getArtist, addArtist, updateArtist, deleteArtist } from "../../redux/actions/artists";
import { getPress, addPress, updatePress, deletePress} from "../../redux/actions/presses";

import { Stack, Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle } from '@mui/icons-material';

import ModalArtist from '../../components/modals/artist';

class Press extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presses:[],
            perpage:parseInt(process.env.REACT_APP_PERPAGE),
            page:1,
            lastId:0,
            availableMore:true,
            err:null,
            isModalOpen:false,
            isLoading:false,
            modalMode:"add",
            iditem:null,
            artist:{
                name:null,
                slug:null,
                description:null,
                weburl:null,
                instagramurl:null,
                mixcloudurl:null,
                soundcloudurl:null,
                idcountry:null,
                idimage:null
            }
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.loadMorePress = this.loadMorePress.bind(this);
    }

    handleDelete(e){
        e.preventDefault();
        if (window.confirm('Are you sure?')) {
            var ctx = this;
            this.setState({isLoading:true});
            this.props.deletePress({id:parseInt(e.currentTarget.getAttribute('data-iditem'))}).then((res)=>{
                console.log(res);
                if(res.code === 200){
                    var joined = ctx.state.presses;
                    let obj = joined.findIndex(o => o.id === res.data.id);
                    joined.splice(obj, 1);
                    ctx.setState({ isLoading:false, presses: joined }, function(){
                        ctx.closeModal();
                    })
                }else{
                    ctx.setState({isLoading:true});
                    alert("Something Error");
                }
            })
        }
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateArtist({id:this.state.iditem, artist:this.state.artist}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.presses;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, presses: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:true});
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addArtist({artist:this.state.artist}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.presses];
                this.setState({ isLoading:false, presses: joined }, function(){
                    this.closeModal();
                })
            }else{
                this.setState({isLoading:true});
                alert("Something Error");
            }
        })
    }

    handleChange(event, newValue){
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        let tmpVal = event.currentTarget.value;
        if(event.currentTarget.name === "slug"){
            tmpVal = event.currentTarget.value.replace(" ", "-").toLowerCase();
        }

        this.setState({
            artist: {
                ...this.state.artist,
                [event.currentTarget.name]: tmpVal//event.currentTarget.value
            }
        });
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            iditem:null,
            artist:{
                name:null,
                slug:null,
                description:null,
                weburl:null,
                instagramurl:null,
                mixcloudurl:null,
                soundcloudurl:null,
                idcountry:null,
                idimage:null
            }
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.presses.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, artist:obj}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMorePress(){
        var ctx = this;
        this.props.getPress({slug:"all", page:this.state.page, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){
                ctx.setState({
                    presses:ctx.state.presses.concat(res.data), 
                    page:ctx.state.page + 1, 
                    lastId:res.data[res.data.length - 1].id
                });

                if(res.data.length !== ctx.state.perpage){
                    ctx.setState({availableMore:false});
                }
            }else{
                ctx.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        this.props.getPress({slug:"all", page:1, perpage: this.state.perpage}).then((res) =>{
            if(res.data !== null){
                this.setState({presses:res.data, page:this.state.page + 1});  
                if(res.length < this.state.perpage){
                    this.setState({availableMore:false})
                }          
            }else{
                this.setState({presses:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {
        const url = this.props.location.pathname;

        function retrieveImage(data){
            if(data !== null){
                return data.fullurl;
            }else{
                return process.env.REACT_APP_DEFAULTIMAGE;//"https://via.placeholder.com/100";
            }            
        }

        function retrieveCategories(data, idrow){
            if(data.length > 0){
                return data.map((category, i)=>(
                    <span key={idrow+i}>{(i !== 0)?", ":""} <a href={"category/"+category.id}>{category.name}</a></span>
                ))
            }else{
                return <span key={idrow}>No Categories</span>
            }            
        }

        function retrieveTags(data, idrow){
            if(data.length > 0){
                return data.map((tag, i)=>(
                    <span key={idrow+i}>{(i !== 0)?", ":""} <a href={"tag/"+tag.id}>{tag.name}</a></span>
                ))
            }else{
                return <span key={idrow}>No Tags</span>
            }            
        }

        return (
        <Box sx={{ display: 'block' }}>
            <Container component="div" className='panel-section-container' maxWidth="xl">
                <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>                    
                    <Button 
                        sx={{ color:"#ffffff" }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        component={NavLink}
                        to={url+"/add"}
                    >Create Press</Button>
                </Box>
                {this.state.presses !== undefined?
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Cover</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell align="center">Caption</StyledTableCell> 
                            <StyledTableCell align="center">Categories</StyledTableCell> 
                            <StyledTableCell align="center">Tags</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.presses.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center">
                                {row.image !== null?
                                <img width={50} height={50}
                                    src={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={row.title}
                                    loading="lazy"
                                />:"No Image"}
                                </StyledTableCell>
                                <StyledTableCell sx={{
                                   maxWidth:"100px",
                                   whiteSpace:"normal"
                                }}>{row.title}</StyledTableCell>
                                <StyledTableCell sx={{
                                   maxWidth:"170px",
                                   whiteSpace:"normal"
                                }}>{row.caption || ""}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {retrieveCategories(row.categories, row.id)}
                                </StyledTableCell>                                
                                <StyledTableCell align="center">
                                    {retrieveTags(row.tags, row.id)}
                                </StyledTableCell>
                                <StyledTableCell sx={{
                                   maxWidth:"120px",
                                   whiteSpace:"normal",
                                   marginLeft:"auto",
                                   marginRight:"auto"
                                }} align="center">
                                    <Stack direction="row" spacing={1} justifyContent="center">                                        
                                        {/* <Button 
                                            variant="contained" 
                                            startIcon={<Edit />} 
                                            // onClick={this.openModal} 
                                            modal-mode="edit" 
                                            data-iditem={row.id}
                                            component={NavLink}
                                            to={url+"/edit/"+row.slug}
                                        > Edit </Button> */}
                                        <Button
                                            sx={{ backgroundColor:"#c30000", "&:hover": {backgroundColor:"#000000", color:"#ffffff"} }}
                                            variant="contained" 
                                            modal-mode="edit" 
                                            data-iditem={row.id}
                                            onClick={this.handleDelete}
                                        > Delete</Button>
                                    </Stack>                                    
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>:"No Data"}
                <Box sx={{ p: 1, m: 1, bgcolor: 'background.paper'}}>
                    {(this.state.availableMore !== false)?
                    <Button onClick={this.loadMorePress} variant="contained" 
                        sx={{
                            margin:'auto',
                            display:'block'
                        }}>Load More</Button>:''}
                </Box>
            </Container>
            { (this.state.isModalOpen !== false)?<ModalArtist isLoading={this.state.isLoading} artist={this.state.artist} modalMode={this.state.modalMode} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} handleChange={this.handleChange} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit}/>:'' }
        </Box>
        )
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        getPress: data => dispatch(getPress(data)),
        addPress: data => dispatch(addPress(data)),
        updatePress: data => dispatch(updatePress(data)),
        deletePress: data => dispatch(deletePress(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Press));