import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getPress = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'press/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addPress = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'press', {
      idimage: payload.idimage,
      title: payload.title,
      caption: payload.caption,
      description: payload.description,
      categories: payload.categories,
      tags: payload.tags,
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    }).then((response) => {
      payload = response.data;
      return payload;
    }).catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updatePress = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'press/'+payload.id, 
      {
        idimage: payload.idimage,
        title: payload.title,
        caption: payload.caption,
        description: payload.description,
        categories: payload.categories,
        tags: payload.tags,
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deletePress = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'press/'+payload.id,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      }).then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}